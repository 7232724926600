/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import '@seczetta/sz-react-core/dist/index.css'

// Support component names relative to this directory:
var componentRequireContext = require.context('../views', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

// Pendo install and initialize
if (window.PENDO_TOKEN && (PENDO_META_DATA.roleLifecycleAdmin || PENDO_META_DATA.roleLifecycleUser || PENDO_META_DATA.roleCollaborationUser || PENDO_META_DATA.roleConsolidationUser)) {
  (function(apiKey){
  (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]||{};o._q=[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
      o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slace.call(arguments,0)));};})(v[w]);
      y=e.createElement(n);y.async=!0;y.id='pendo-script';y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);
  })(window,document,'script','pendo');

  function initPendo() {
    pendo.initialize({
      visitor: {
        id: PENDO_META_DATA.visitorId,
        language: PENDO_META_DATA.language,
        roleLifecycleAdmin: PENDO_META_DATA.roleLifecycleAdmin === 'true',
        roleLifecycleUser: PENDO_META_DATA.roleLifecycleUser === 'true',
        roleCollaborationUser: PENDO_META_DATA.roleCollaborationUser === 'true',
        roleConsolidationUser: PENDO_META_DATA.roleConsolidationUser === 'true'
      },
      account: {
        id: window.TENANT,
        nerm_pod: window.CLUSTER,
        pod: window.RAILS_ENV
      },
    });
  }

  var script = document.getElementById('pendo-script');
  script.addEventListener('load', initPendo);
  })(PENDO_TOKEN);
}
